import { useState } from "react"
import tw from "twin.macro"
import { percentageDifferent, toPercent } from "../../../helpers"
import Tabs from "../../Tabs.tsx"

const LifestyleTabs: React.FC<{activeTab: number, toggleTabs: any, city1: any, city2: any, costOfLiving: string}> = ({activeTab, toggleTabs, city1, city2, costOfLiving}) => {
    const city1opt = city1[0]
    const city2opt = city2[0]

    const handleCalculation = (city1Value: string, city2Value:string) =>{
     let num1
     let num2
  
     if(city1Value[0] == "(") {
       num1 = (Number("-" + city1Value.replace(/[()%]/g, "")))
     } else if(city1Value[0] !== "(") num1 = (Number(city1Value.replace(/[()%]/g, "")))

     if(city2Value[0] == "(") {
       num2 = (Number("-" + city2Value.replace(/[()%]/g, "")))
     } else if(city2Value[0] !== "(") num2 = (Number(city2Value.replace(/[()%]/g, "")))

     return Number((((num2 - num1)/num1)*100).toFixed(1))
    }
    
    return (
        <nav css={[tw`w-full bg-red-500 h-48 lg:h-56`]}>
        <div
          className="no-scrollbar lg:none"
          css={[
            tw`mx-auto overflow-x-scroll overflow-y-visible lg:overflow-visible content-end`,
          ]}
        >
          <ul
            css={[
              tw`items-end flex flex-row lg:flex-none lg:grid lg:grid-cols-12 gap-2`,
            ]}
          >
        <Tabs activeTab={activeTab} toggleTabs={toggleTabs} tabNum={1} preheader={"Cost of Living"} header={costOfLiving} subheader={"lower"} />
        <Tabs activeTab={activeTab} toggleTabs={toggleTabs} tabNum={2} preheader={"Average Housing Cost Variance"} header={Math.abs(handleCalculation(city1opt.TotalHousingCostValue, city2opt.TotalHousingCostValue) ) + "%"} subheader={Math.sign(handleCalculation(city1opt.TotalHousingCostValue, city2opt.TotalHousingCostValue)) == +1 ? "lower" : "higher"}  />
        <Tabs activeTab={activeTab} toggleTabs={toggleTabs} tabNum={3} preheader={"Average Transportation Cost Variance"} header={Math.abs(handleCalculation(city1opt.TotalTransportCostValue, city2opt.TotalTransportCostValue) ) + "%"} subheader={Math.sign(handleCalculation(city1opt.TotalTransportCostValue, city2opt.TotalTransportCostValue)) == +1 ? "lower" : "higher"}  />
        <Tabs activeTab={activeTab} toggleTabs={toggleTabs} tabNum={4} preheader={"Average Grocery Cost Variance"} header={Math.abs(handleCalculation(city1opt.TotalFoodValue, city2opt.TotalFoodValue) ) + "%"} subheader={Math.sign(handleCalculation(city1opt.TotalFoodValue, city2opt.TotalFoodValue)) == +1 ? "lower" : "higher"}/>
        <Tabs activeTab={activeTab} toggleTabs={toggleTabs} tabNum={5} preheader={"Average Goods and Services Cost Variance"} header={Math.abs(handleCalculation(city1opt.TotalEntertainmentValue, city2opt.TotalEntertainmentValue) ) + "%"} subheader={Math.sign(handleCalculation(city1opt.TotalEntertainmentValue, city2opt.TotalEntertainmentValue)) == +1 ? "lower" : "higher"} />
        <Tabs activeTab={activeTab} toggleTabs={toggleTabs} tabNum={6} preheader={"Average Health Care Cost Variance"} header={Math.abs(handleCalculation(city1opt.TotalHealthcareValue, city2opt.TotalHealthcareValue) ) + "%"}  subheader={Math.sign(handleCalculation(city1opt.TotalHealthcareValue, city2opt.TotalHealthcareValue)) == +1 ? "lower" : "higher"} />
          </ul>
        </div>
      </nav>
    )
}

export default LifestyleTabs