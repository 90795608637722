import tw from "twin.macro"
import * as React from "react"
import FinancialIcon from "../../../images/icon-financial@3x.png"
import { toUsd, toUsdWithCents } from "../../../helpers"

const Transportation: React.FC<{ city1: any; city2: any }> = ({
  city1,
  city2,
}) => {
  return (
    <>
      <div css={[tw`flex flex-col`]}>
        <div css={[tw`flex flex-row justify-center pb-4`]}>
          <img
            src={FinancialIcon}
            alt=""
            css={[tw`h-8 w-8 flex justify-end mr-2`]}
          />{" "}
          <h6 css={[tw`text-beige text-center`]}>{city1[0].TransportationLabel} Costs</h6>
        </div>
        <div css={[tw`flex flex-row justify-center pb-5 lg:pb-20`]}>
        <h6 css={city1[0].Name.length + city2[0].Name.length > 20 ?[tw`text-base text-center text-white`] : [tw`text-center text-white`]}>
            Compare {city1[0].Name} + {city2[0].Name}
          </h6>
        </div>
      </div>
      <div css={[tw`grid grid-cols-1 xl:grid-cols-5`]}>
        <div css={[tw`flex col-span-1 lg:col-span-3 lg:col-start-2 pb-7 text-white`]}>
          <table css={[tw`table-fixed w-full`]}>
            <thead>
              <tr>
                <th css={[tw`w-44 text-sm py-1 lg:py-0 lg:w-80`]}></th>
                <th>{city1[0].Name}</th>
                <th>{city2[0].Name}</th>
              </tr>
            </thead>
            <tbody>
              {city1[0].AvgCommuteLabel != "Not in Use" ? <tr css={[tw`bg-gray-100/20 `]}>
                <td css={[tw`font-bold text-left pl-2 py-3`]}>
                  {city1[0].AvgCommuteLabel}
                </td>
                <td css={[tw`text-center`]}>{city1[0].AvgCommuteValue}</td>
                <td css={[tw`text-center`]}>{city2[0].AvgCommuteValue}</td>
              </tr> : null}
              {city1[0].AvgGasolineLabel != "Not in Use" ? <tr css={[tw`py-5`]}>
                <td css={[tw`font-bold text-left pl-2 py-3`]}>
                  {city1[0].AvgGasolineLabel}
                </td>
                <td css={[tw`text-center`]}>{city1[0].AvgGasolineValue}</td>
                <td css={[tw`text-center`]}>{city2[0].AvgGasolineValue}</td>
              </tr> : null}
              {city1[0].AvgCarInsuranceLabel != "Not in Use" ? <tr css={[tw`bg-gray-100/20 py-5`]}>
                <td css={[tw`font-bold text-left pl-2 py-3`]}>
                  {city1[0].AvgCarInsuranceLabel}
                </td>
                <td css={[tw`text-center`]}>{city1[0].AvgCarInsuranceValue}</td>
                <td css={[tw`text-center`]}>{city2[0].AvgCarInsuranceValue}</td>
              </tr>: null}
              {city1[0].AvgVehicleOpLabel != "Not in Use" ? <tr css={[tw`bg-gray-100/20 py-5`]}>
                <td css={[tw`font-bold text-left pl-2 py-3`]}>
                  {city1[0].AvgVehicleOpLabel}
                </td>
                <td css={[tw`text-center`]}>{city1[0].AvgVehicleOpValue}</td>
                <td css={[tw`text-center`]}>{city2[0].AvgVehicleOpValue}</td>
              </tr> : null }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Transportation
