import * as React from "react"
import tw from "twin.macro"
import { TabsProps } from "./Tabs.d"

const Tabs: React.FC<TabsProps> = ({ activeTab, toggleTabs, tabNum, preheader, header, subheader}) => {
  return (
    <li
    css={[
      tw`cursor-pointer text-white text-xl font-bold inline-block relative w-44 lg:w-auto lg:col-span-2 `,
    ]}
    className="tab"
  >
    <div
      css={
        activeTab == tabNum
          ? [
              tw`bg-red-300 h-32 lg:h-40 w-44 lg:w-auto shadow-xl scale-y-110 relative `,
            ]
          : [tw`bg-red-600 w-44 lg:w-auto h-32 lg:h-40 relative`]
      }
    ></div>
    <div
      onClick={() => toggleTabs(tabNum)}
      css={[
        tw`absolute top-0 bottom-0 left-0 right-0 mx-auto grid grid-cols-1 items-center content-center lg:px-3 lg:py-6 text-center z-30 `,
      ]}
    >
      <p css={[tw`text-sm lg:text-lg`]}>{preheader}</p>
      <h1 css={activeTab == tabNum ? [tw`text-3xl lg:text-5xl text-opacity-100`] : [tw`text-3xl lg:text-5xl text-opacity-50`]} className="subhead">
        {header}
      </h1>
      <p css={[tw`text-sm lg:text-xl`]}>{subheader}</p>

    </div>
    <div
      className="oval"
      css={[
        tw`absolute top-0 bottom-0 left-0 right-0 h-24 w-40 lg:h-32 lg:w-full overflow-hidden`,
      ]}
    ></div>
    <div
      css={
        activeTab == tabNum
          ? [
              tw`h-3 w-4 left-0 right-0 top-[-30px] mx-auto absolute border-b-red-300 border-b-[24px] border-r-transparent border-r-[50px] border-l-transparent border-l-[50px]`,
            ]
          : null
      }
    ></div>
  </li>
  )
}

export default Tabs